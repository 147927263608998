.logo-container {
  width: 70%;
  padding: 25px;
  @media screen and (max-width: 800px) {
    width: 50px;
    padding: 0;
  }
}

.options {
  width: 50%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  @media screen and (max-width: 800px) {
    width: 80%;
  }

  .option {
    padding: 10px 15px;
  }
}

.social {
  height: 70px;
  width: 100%;
  align-content: flex-end;
  text-align: right;
  float: right;
  /* top | right | bottom | left */
  padding: 10px 0px 0px 0px;
  @media screen and (max-width: 800px) {
    height: 60px;
    padding: 10px;
    margin-bottom: 20px;
  }
  a {
    color: #3f51b5;
  }
}
