.hide-btn {
  display: none;
  visibility: hidden;
  position: relative;
  width: 50%;
}

.show-btn {
  display: flex;
  position: relative;
  width: 50%;
}

.circle-img {
  width: 300px;
  height: 300px;
  overflow: hidden;
  background-color: gray;
}

.circle-img img {
  height: 100%;
  transform: translateX(-50%);
  margin-left: 50%;
}

.image-container {
  position: relative;
  width: 100%;
  display: flex;
  display: -webkit-flex;
  flex-wrap: wrap;
}
.image-container:before {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0);
}
.image-container:hover::before {
  background-color: rgba(0, 0, 0, 0.5);
}
.image-container img {
  display: inline-block;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 10px;
}
.image-container button {
  position: absolute;
  top: 60%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  opacity: 0;
}
.image-container:hover button {
  opacity: 1;
}
