#footer-container {
  padding-top: 10px;
  align-content: flex-center;
  text-align: center;
  float: center;
  margin: 0 auto;
  background-color: #e9ecef;
}

.social {
  height: 70px;
  width: 100%;
  align-content: flex-end;
  text-align: right;
  float: right;
  /* top | right | bottom | left */
  padding: 10px 0px 0px 0px;
  @media screen and (max-width: 800px) {
    align-content: flex-center;
    text-align: center;
    float: center;
    height: 60px;
    padding: 10px;
    margin-bottom: 20px;
  }
}

.nav-items {
  height: 70px;
  width: 100%;
  align-content: flex-start;
  text-align: left;
  float: left;
  /* top | right | bottom | left */
  padding: 10px 0px 0px 0px;
  @media screen and (max-width: 800px) {
    align-content: flex-center;
    text-align: center;
    float: center;
    height: 60px;
    padding: 10px;
    margin-bottom: 20px;
  }
}

a {
  color: #3f51b5;
}
