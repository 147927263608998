h2 {
  position: absolute;
  color: White;
  left: 0;
  right: 0;
  width: 100%;
  text-align: center;
}

.item-button {
  display: flex;
  position: relative;
  color: White;
  width: 100%;
  text-align: center;
}
